import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyCvLbP81e_RA6yqf-joHcvxHqCRGdydIns",
  authDomain: "dj-summit.firebaseapp.com",
  databaseURL: "https://dj-summit.firebaseio.com",
  projectId: "dj-summit",
  storageBucket: "dj-summit.appspot.com",
  messagingSenderId: "215347900804",
  appId: "1:215347900804:web:f1b68a0810db3123b9add1",
  measurementId: "G-Z6T27HCGSK"
};

// const config = {
//   apiKey: "AIzaSyCNgHoMr9vtnL_HRHTHnjhFAyo52TuY0kA",
//   authDomain: "djfarmademo.firebaseapp.com",
//   databaseURL: "https://djfarmademo.firebaseio.com",
//   projectId: "djfarmademo",
//   storageBucket: "djfarmademo.appspot.com",
//   messagingSenderId: "83133899785",
//   appId: "1:83133899785:web:c7dae77a3d8ff05b6084d8",
//   measurementId: "G-BNZQKXERYQ",
// };

// ********** QnA **********//

app.initializeApp(config);
const authx = app.auth();
const dbx = app.firestore();
const storage = app.storage();

// ********** QnA **********//



class Firebase {
  constructor() {
    //firebase reference
    this.app = app;
    this.auth = app.auth();
    this.db = app.firestore();
    this.firestore = app.firestore();
    this.database = app.database();
    var firebaseRef = app.database().ref();
    this.analytics = app.analytics();
    // eslint-disable-next-line no-undef
    // this.firechat = new Firechat(firebaseRef);
    
    this.alreadyLoggedIn = false;
    this.showEmailMessage = false;
    this.emailVerified = false;
    this.currentUser = null;
    //videocall region
    this.isAllowedTownHall = false;
    this.videoRoomAdmin = false;
    this.videoCallRoomName = "";
    this.homeVideoPrepareFunction = null;
    //
    //video mulitple 
    this.videoRoooms = [];
    this.moreThanOneRoom = false;
    this.homeAllRoomVideoPrepareFunction = null;


    this.timer = {
      timerValue: 0,
      isTimmerOn: false,
      timerRef: null,
    }

    window.firebaseObj = this;
  }

  getFirestore = () => {
    return this.firestore;
  };

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    this.auth.signOut();
  };

  //change according to your need 
  //filetag refers to  <input type="file" value="upload" id="fileButton" /> here
  uploadImage = (fileTag) => {
    const metadata = {
      contentType: 'image/jpeg'
    };
    const file = fileTag.files[0];
    const storageRef = app.storage().ref('profileImages/' + file.name)
    const task = storageRef.put(file, metadata);
    let photoURL;
    task.on('state_changed',
      function progress(snapshot) {

      },
      function error(er) {
        console.log(er.code);
      },
      function complete() {
        console.log("Upload complete");
        task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log('File available at ', downloadURL);
          photoURL = downloadURL;
        });
      });
  };

  updatePhoto = async (user, photo) => {
    try {
      const updatedPhoto = await app.firestore().collection("users").doc(user.uid).update({
        photoUrl: photo
      })
      console.log("updated photo succefully");
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("code: " + errorCode + " & ErrorMsg: " + errorMessage);
    }
  };

  //please pass the user returned from the firebase auth function calls and new name to be updated
  updateName = async (user, newName) => {
    try {
      const updatedUser = await user.updateProfile({
        displayName: newName
      })
      console.log("name Updated:" + newName + "!!");
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("code: " + errorCode + " & ErrorMsg: " + errorMessage);
    }
  };

  //please pass the user returned from the firebase auth function calls and new mobile number to be updated
  updateMobileNumber = async (user, mobileNumber) => {
    try {
      const updatedDoc = await app.firestore().collection("users").doc(user.uid).update({
        mobileNumber: mobileNumber
      })
      console.log("updated mobile Number succefully");
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("code: " + errorCode + " & ErrorMsg: " + errorMessage);
    }
  };

  checkUserAdmin = async () => {
    if (this.currentUser) {
      try {

          // await GetRoomNameAndAdmin_method1();
          await this.GetRoomNameAndAdmin_method2();
      }
      catch (err) {
        console.log(err);
      }
    }
  }

  GetRoomNameAndAdmin_method1 = async() =>
  {
    try {
      var self = this;

      const userDoc = await app.firestore().collection("users").doc(self.currentUser.uid).get();
      if (userDoc.data()) {
        const dailycoRoomName = userDoc.data().dailycoRoomName;
        console.log(dailycoRoomName);

        if (dailycoRoomName) {
          const dailycoRoomDoc = await app.firestore().collection("dailyco").doc(dailycoRoomName).get();
          const adminArray = dailycoRoomDoc.data().admin;
          console.log(adminArray);

          if (adminArray.length > 0) {
            if (adminArray.indexOf(self.currentUser.uid) > -1) {
              console.log("returning true is admin");
              this.videoRoomAdmin = true;
              return true;
            } else {
              console.log("returning false is admin");
              this.videoRoomAdmin = false;
              return false;
            }
          }
          else {
            console.log("returning false is admin");
            this.videoRoomAdmin = false;
            return false;
          }
        } else {
          console.log("No room is given to the user");
        }
      }

    }
    catch (err) {
      console.log(err);
    }
  }


  GetRoomNameAndAdmin_method2 = async() =>
  {
    try {
     const videoCallDocs = await app.firestore().collection("dailyco").where("members","array-contains",this.currentUser.uid).get();

     if(videoCallDocs !== undefined)
     {
       if(!videoCallDocs.empty)
       {
          if(videoCallDocs.docs.length >= 1)
          {
            console.log(videoCallDocs.docs);

            if(videoCallDocs.docs.length === 1)
            {
              console.log("Can go to TownHall");
                this.isAllowedTownHall = true;
                var data = videoCallDocs.docs[0].data();
                var adminArray = data.admin;

                const index = adminArray.indexOf(this.currentUser.uid);
                if(index !== -1)
                {
                  this.videoRoomAdmin = true;
                }else
                {
                  this.videoRoomAdmin = false;
                }
                this.videoCallRoomName = data.roomName;
                // console.log(this.currentUser.uid);
                //  console.log(this.videoRoomAdmin, this.videoCallRoomName);
                if(this.homeVideoPrepareFunction)
                {
                  this.homeVideoPrepareFunction();
                }
                this.videoRoooms = {};
                this.moreThanOneRoom = false;
            }else
            {
              console.log("Can go to allTownHall");
              var self = this;
              videoCallDocs.docs.forEach(doc => {
                 self.videoRoooms.push(doc.data().roomName) 
              });
              this.moreThanOneRoom = true;
              this.isAllowedTownHall = true;
              this.videoRoomAdmin = false;
              this.videoCallRoomName = videoCallDocs.docs[0].data().roomName;
              // this.videoCallRoomName = data.roomName;
              if(this.homeAllRoomVideoPrepareFunction)
              {
                this.homeAllRoomVideoPrepareFunction();
              }
            }
            
            
          }
       }else
        {
          // console.log("Can not go to TownHall");
          this.isAllowedTownHall = false;
        }
     }

    }
    catch (err) {
      console.log(err);
    }
  }

  //#region  Anayltics Functions
  checkForAnalytics = () =>
  {
      const rdb = this.database;
      const firebaseapp = this.app;
      const currentUser = this.currentUser;
      
      var userData = rdb.ref('userData/' + currentUser.uid)
      userData.once('value').then(function (snapshot) {
          // console.log(snapshot.val())
          if (snapshot.exists()) {
              // console.log("user exits");
          } else {
              rdb.ref('userData/' + currentUser.uid).set({
                  entryTime: firebaseapp.database.ServerValue.TIMESTAMP,
                  lastEntry: firebaseapp.database.ServerValue.TIMESTAMP,
                  Azura: 0,
                  Arian: 0,
                  Avior: 0,
                  Akuna: 0,
                  Coronus: 0,
                  Vega: 0,
                  Photobooth: 0,
                  Game: 0,
                  CV7Day1: 0,
                  CV7Day2: 0,
                  audi2Time: 0,
                  gotDisconnected: false,
              }, function (error) {
                  if (error) {
                      console.log("error occurred");
                      // The write failed...
                  } else {
                      console.log("Data saved successfully!");
                      // Data saved successfully!
                  }
              });
          }
      });
  }

  addStallVisit = (stallName) =>
  {
    this.analytics.logEvent(stallName);
    var self = this;
    //read data
    var userData = this.database.ref('userData/' + this.currentUser.uid)
    userData.once('value').then(function (snapshot) {
        // console.log(snapshot.val())
        if (snapshot.exists()) {
            var previousValue = 0;
            //getData
            var obj = snapshot.val();
            Object.keys(obj).forEach(key => {
                if(key === stallName)
                {
                    previousValue = obj[key];
                }
            });
            previousValue += 1;
            //update data
            userData.update({
                [stallName]: previousValue,
            }, function (error) {
                if (error) {
                    console.log("error occurred while updating analytics for: ", stallName);
                } else {
                    // console.log("Data saved successfully for: ", stallName);
                }
            });
        } else {
            self.checkForAnalytics();
        }
    });
  }

  startAudi2Timer = () =>
  {
    if(this.timer.isTimmerOn)
    {
        return;
    }

    const rdb = this.database;
    const firebaseapp = this.app;
    const currentUser = this.currentUser;
    var self = this;

    var userData = rdb.ref('userData/' + currentUser.uid)
    userData.once('value').then(function (snapshot) {

        if (snapshot.exists()) {
            self.timer.timerValue = snapshot.val().audi2Time;

            if (snapshot.val().gotDisconnected) {
                let entryTime = parseInt(snapshot.val().entryTime);
                let lastEntry = parseInt(snapshot.val().lastEntry);
                let extraTime = Math.floor((lastEntry - entryTime) / 1000);
                self.timer.timerValue += extraTime;
            }

            userData.update({
                audi2Time: self.timer.timerValue,
                entryTime: firebaseapp.database.ServerValue.TIMESTAMP,
                gotDisconnected: false
            }, function (error) {
                if (error) {
                    console.log("error occurred while updating timer in rdb");
                } else {
                    // console.log("Data saved successfully!");
                }
            });

            userData.onDisconnect().update({
                lastEntry: firebaseapp.database.ServerValue.TIMESTAMP,
                gotDisconnected: true
            }).then(function () {
              // console.log("onDiscoonect added");
            });

            self.timer.isTimmerOn = true;

            self.timer.timerRef = setInterval(function () {
                self.timer.timerValue += 1;
                // console.log(self.timer.timerValue)
            }, 1000);
        } else {
          self.checkForAnalytics(); 
        }
    });
  }

  StopAudi2Timer = () =>
  {
    if(!this.timer.isTimmerOn)
    {
      return;
    }
    const rdb = this.database;
    const firebaseapp = this.app;
    const currentUser = this.currentUser;
    var self = this;
    
    clearInterval(this.timer.timerRef);
    this.timer.isTimmerOn = false;
    
    var userData = rdb.ref('userData/' + currentUser.uid)
    userData.update({
        audi2Time: self.timer.timerValue,
        lastEntry: firebaseapp.database.ServerValue.TIMESTAMP
    }, function (error) {
        if (error) {
            console.log("error occurred while stopping timer");
        } else {
            // console.log("Data saved successfully!");
            userData.onDisconnect().cancel();
        }
    });
  }
  //#endregion


  checkForUserData= async () =>
  {
    try {
      const updatedDoc = await app.firestore().collection("users").doc(this.currentUser.uid).get()
      if(!updatedDoc.exists)
      {
        await app.firestore().collection("users").doc(this.currentUser.uid).set({
          id: this.currentUser.uid,
          nickname: this.currentUser.displayName,
          photoURL: "https://storage.googleapis.com/virtual-event-273009.appspot.com/ic_default_avatar.png",
        });
      }
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("code: " + errorCode + " & ErrorMsg: " + errorMessage);
    }
  }

}

export default Firebase;
export { dbx, authx, storage };
