// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import "./App.css";
import { Route, HashRouter, Redirect } from "react-router-dom";
import Login from "./components/Login";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Loader from "./components/Loader";
import { FirebaseContext } from "./firebase";
import { createHashHistory } from "history";
import { isMobileOnly} from 'react-device-detect';
import LandscapeMessage from './components/landscapeMessage';

export const history = createHashHistory();

class App extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = 
    {
      update: false
    }
    this.actionCodeSettings = {
      url: "https://summit.djvirtualevents.com/",
      handleCodeInApp: false
    };
  }
  componentDidMount() {
    var self = this;
    this.listener = this.context.auth.onAuthStateChanged((authUser) => {
      if (authUser) 
      {
        self.context.currentUser = authUser;
        self.context.emailVerified = authUser.emailVerified;

        if(authUser.emailVerified)
        {
          self.context.showEmailMessage = false;
          self.UserOnlineTest(authUser);
          // history.push("/home");
        }
        else{

          self.context.showEmailMessage = true;
          self.setState({
            update: false
          });
          authUser.sendEmailVerification(self.actionCodeSettings).then(function() {
            console.log("Mail Sent!!");
           }).catch(function(error) {
            console.log("err:"+error);
          });
          this.context.doSignOut();
        }
       
      } else {
        console.log("user not logged in--");
        history.push("/login");
      }
    });
  }

  //#region  utility functions
  UserOnlineTest = (user) => 
  {
    var self = this;
    var users = this.context.database.ref().child('loggedInUser').child(user.uid);
    var query = users.orderByChild('state');
    query.once("value", snapshot => {
      if (snapshot.exists()) {
        // console.log("old user");
        const userData = snapshot.val();
        console.log("status:", userData.state);

        if (userData.state === "online") {
          // console.log("Already Signed In");
          self.context.auth.signOut();
          self.context.alreadyLoggedIn = true;
          history.push("/login");
          
          this.setState({
            update: true
          });
          // history.push({
          //   pathname: '/login',
          //   state: { online: true}
          // })
        } else {
          // console.log("user camer back");
          self.validUser(user);
        }
      } else {
        // console.log("first time user");
        self.validUser(user);
      }
    });
  }

  validUser(user)
  {
    console.log("valid user");
    this.updateUserStatus(user);
    this.context.alreadyLoggedIn = false;
    // this.context.checkUserAdmin();
    this.context.checkForUserData();
    this.context.checkForAnalytics();
    history.push("/home");
  }

  updateUserStatus(user)
  {
    const uid = user.uid;
    const userStatusDatabaseRef = this.context.database.ref('/loggedInUser/' + uid);
    const isOfflineForDatabase = {
        state: 'offline',
        last_changed: this.context.app.database.ServerValue.TIMESTAMP,
    };
    const isOnlineForDatabase = {
        state: 'online',
        last_changed: this.context.app.database.ServerValue.TIMESTAMP,
    };
  
    this.context.database.ref('.info/connected').on('value', function(snapshot) {
        if (snapshot.val() == false) {
          return;
        };
        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
        userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });
  }

 
  //#endregion

  componentWillUnmount() {
    this.listener();
  }

  render() {
    return (
      <>
      { isMobileOnly ?
            (<LandscapeMessage ></LandscapeMessage>): (null)
          }

      <HashRouter>
        <Route exact path="/">
          <Redirect to="/loader" />
        </Route>
        {/* <Route path="/login" component={Login} /> */}
        <Route path="/login" render={(props) => <Login {...props} alreaylogged={this.context.alreadyLoggedIn} 
                                                                  emailVerified={this.context.emailVerified} 
                                                                  showEmailMessage = {this.context.showEmailMessage} />} />

        <Route path="/contact" component={Contact} />
        <Route path="/home" component={Home} />
        <Route path="/loader" component={Loader} />
      </HashRouter>
    </>

    );
  }
}

export default App;
