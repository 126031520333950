import React from "react";
import Firebase, {dbx, authx} from "../firebase/firebase";
import QABox from "./QABox";

class App1 extends React.Component {
  
  onHeadingClick(event, item) {
    this.props.onHeadingClick(event, item);
  }

    render() {
    const { item } = this.props;

        return (
          <div className="second-level-nav">
         <h3 className="second-level-nav__title has-icon" onClick={event => this.onHeadingClick(event, item)}><i className="second-level-nav__icon icon-angle-back"></i> Q&amp;A</h3>
          <QABox/></div>
        )
    }
}

export default App1;
